import {Component, OnInit, ViewChild} from '@angular/core';
import {RuleInterface} from "./_models/Rule";
import {PeriodSelectorComponent} from "./period-selector/period-selector.component";
import {CrontabLog} from "./_models/CrontabLog";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


    crontab_result: string = '';

    crontab_command: string;
    crontab_log : CrontabLog;
    crontab_rules: {
        "minutes": RuleInterface[],
        "hours": RuleInterface[],
        "monthdays": RuleInterface[],
        "months": RuleInterface[],
        "weekdays": RuleInterface[],
    };


    @ViewChild('periodSelectorMinutes')
    periodSelectorMinutes: PeriodSelectorComponent;

    @ViewChild('periodSelectorHours')
    periodSelectorHours: PeriodSelectorComponent;

    @ViewChild('periodSelectorDayOfMonth')
    periodSelectorDayOfMonth: PeriodSelectorComponent;

    @ViewChild('periodSelectorMonth')
    periodSelectorMonth: PeriodSelectorComponent;

    @ViewChild('periodSelectorDayOfWeek')
    periodSelectorDayOfWeek: PeriodSelectorComponent;

    public updateCommand( command : {
        command : string,
        log : CrontabLog,
    }){
        this.crontab_command = command.command;
        this.crontab_log = command.log;
        console.log(this.crontab_log);
        this.updateResult();
    }

    public updateRule(ruleId: string, rules: RuleInterface[]) {
        this.crontab_rules[ruleId] = rules;
        this.updateResult();
    }

    ngOnInit(): void {
        this.crontab_command = '';
        this.crontab_log = new CrontabLog();
        this.updateRules();
    }

    updateRules(): void {
        console.log("rules are updated !");
        this.crontab_rules = {
            "minutes": this.periodSelectorMinutes.getRules(),
            "hours": this.periodSelectorHours.getRules(),
            "monthdays": this.periodSelectorDayOfMonth.getRules(),
            "months": this.periodSelectorMonth.getRules(),
            "weekdays": this.periodSelectorDayOfWeek.getRules(),
        };
        this.updateResult();
    }

    updateResult(): void {
        let minutes: string = this.crontab_rules.minutes.map(rule => rule.getRule()).join(',');
        let hours: string = this.crontab_rules.hours.map(rule => rule.getRule()).join(',');
        let monthdays: string = this.crontab_rules.monthdays.map(rule => rule.getRule()).join(',');
        let months: string = this.crontab_rules.months.map(rule => rule.getRule()).join(',');
        let weekdays: string = this.crontab_rules.weekdays.map(rule => rule.getRule()).join(',');

        console.log('minutes', minutes, this.crontab_rules.minutes);
        console.log('hours', hours, this.crontab_rules.hours);
        console.log('monthdays', monthdays, this.crontab_rules.monthdays);
        console.log('months', months, this.crontab_rules.months);
        console.log('weekdays', weekdays, this.crontab_rules.weekdays);

        this.crontab_result = minutes + " " + hours + " " + monthdays + " " + months + " " + weekdays + "\t" + this.crontab_command;

        if (this.crontab_log.isEnabled) {
            this.crontab_result += '  >';
            if (this.crontab_log.isToAppend) {
                this.crontab_result += '>';
            }
            this.crontab_result += ' ' + this.crontab_log.logFile;
        }

        console.log(this.crontab_result);
    }

}

