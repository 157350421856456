import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {ButtonsModule, ModalModule} from "ngx-bootstrap";
import {FormsModule} from "@angular/forms";
import { AddValueModalComponent } from './add-value-modal/add-value-modal.component';
import { PeriodSelectorComponent } from './period-selector/period-selector.component';
import { CommandSelectorComponent } from './command-selector/command-selector.component';
import {AngularFontAwesomeModule} from "angular-font-awesome";

@NgModule({
  declarations: [
    AppComponent,
    AddValueModalComponent,
    PeriodSelectorComponent,
    CommandSelectorComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    AngularFontAwesomeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
