import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CrontabLog} from "../_models/CrontabLog";

@Component({
    selector: 'app-command-selector',
    templateUrl: './command-selector.component.html',
    styleUrls: ['./command-selector.component.css']
})
export class CommandSelectorComponent implements OnInit {

    isLogSaved: boolean = false;
    isLogAppend: boolean = false;
    logPath: string;

    crontab_cmd: string = '';
    crontab_log: CrontabLog = new CrontabLog();

    @Output()
    onCommandUpdate = new EventEmitter<{
        command: string,
        log: CrontabLog,
    }>();

    updateValue() {
        this.onCommandUpdate.emit({
            command: this.crontab_cmd,
            log: this.crontab_log
        })
    }

    constructor() {
    }

    ngOnInit() {
        this.crontab_log = new CrontabLog();
    }

}
