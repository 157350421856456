import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RuleInterface, AllRule, PeriodRangeRule, PeriodRule, RangeRule, ValueRule} from "../_models/Rule";
import {ModalDirective} from "ngx-bootstrap";
import {strictEqual} from "assert";

@Component({
    selector: 'app-period-selector',
    templateUrl: './period-selector.component.html',
    styleUrls: ['./period-selector.component.css']
})
export class PeriodSelectorComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        this.values = [];
        for( let i = this.valueMin; i <= this.valueMax; i++){
            this.values.push({
                displayedValue : i.toString().padStart(2, '0'),
                value: i,
                isEnabled: true,
            })
        }
    }

    @ViewChild('periodSelectorModal')
    periodSelectorModal: ModalDirective;

    @ViewChild('addRangeModal')
    public addRangeModal: ModalDirective;

    @ViewChild('addPeriodModal')
    public addPeriodModal: ModalDirective;

    @ViewChild('addPeriodRangeModal')
    public addPeriodRangeModal: ModalDirective;

    specificRules: any[] = [];

    @Input('min')
    valueMin: number = 0;

    @Input('max')
    valueMax: number = 59;

    values: { displayedValue: string, value: number, isEnabled: boolean }[] = [];

    tmp_add_value: number = 0;
    tmp_add_range_start: number = 0;
    tmp_add_range_end: number = 0;
    tmp_add_period: number = 1;


    @Output()
    onRuleUpdate = new EventEmitter<RuleInterface[]>();

    /**
     * Show popup
     */
    public show(): void {
        this.periodSelectorModal.show();
    }

    public selectAll() : void {
        this.specificRules = [];
        this.values = this.values.map( val => {
            val.isEnabled = true;
            return val;
        });
        this.onRuleUpdate.emit(this.getRules());
    }


    selectNone(){
        this.specificRules = [];
        this.values = this.values.map( val => {
            val.isEnabled = false;
            return val;
        })
    }

    public selectFirst() : void {
        this.selectNone();
        this.tmp_add_value = this.valueMin;
        this.addValue();
    }

    public selectOne( val) : void {
        this.tmp_add_value = val;
        this.addValue();
    }

    isAllSelected() : boolean {
        let allIsEnabled = true;
        for( let val of this.values){
            allIsEnabled = allIsEnabled && val.isEnabled;
        }

        return allIsEnabled
    }

    /**
     * Get the rules
     */
    public getRules(): RuleInterface[] {

        if (this.isAllSelected()){
            return [new AllRule()];
        }

        return this.specificRules;
    }

    /**
     * Add a rule and emit update
     * @param rule
     */
    private addRule(rule: RuleInterface): void {
        this.specificRules.push(rule);
        this.onRuleUpdate.emit(this.getRules());
    }

    private resetValue(): void {
        this.tmp_add_value = 0;
    }

    private resetRange(): void {
        this.tmp_add_range_start = 0;
        this.tmp_add_range_end = 0;
    }

    private resetPeriod(): void {
        this.tmp_add_period = 1;
    }

    /**
     * Add a rule based on value
     */
    public addValue(): void {
        if (this.isAllSelected()){
            this.selectNone();
        }

        // Check if rule already exists
        if( this.specificRules.filter( (val : RuleInterface) => {

            if( val instanceof ValueRule){
                return val.getRule() == this.tmp_add_value.toString()
            }

            if( val instanceof RangeRule){
                return this.tmp_add_value >= val.range_start && this.tmp_add_value <= val.range_end;
            }

            return false;
        }).length > 0){
            console.warn('Duplicate. Skipping');
            return;
        }

        let rule = new ValueRule();
        rule.value = this.tmp_add_value;
        this.values = this.values.map( val => {
            if( val.value == rule.value ){
                val.isEnabled = true;
            }
            return val;
        });
        this.addRule(rule);
        this.resetValue();
    }

    /**
     * Add a rule based on range
     */
    public addRange(): void {
        if (this.isAllSelected()){
            this.selectNone();
        }

        // Remove existing duplicate rules
        this.specificRules = this.specificRules.filter( (rule: RuleInterface) => {
            if( rule instanceof ValueRule){
                let ruleValue = parseInt(rule.getRule());
                console.log( ruleValue);
                return ruleValue < this.tmp_add_range_start || ruleValue > this.tmp_add_range_end;
            }
            return true;
        });

        let rule = new RangeRule();
        rule.range_start = this.tmp_add_range_start;
        rule.range_end = this.tmp_add_range_end;
        this.values = this.values.map( val => {
            if( val.value >= rule.range_start && val.value <= rule.range_end ){
                val.isEnabled = true;
            }
            return val;
        });
        this.addRule(rule);
        this.resetRange();
    }

    /**
     * Add a rule based on period
     */
    public addPeriod(): void {
        if (this.isAllSelected()){
            this.selectNone();
        }
        let rule = new PeriodRule();
        rule.periodicity = this.tmp_add_period;
        this.values = this.values.map( val => {
            if( val.value % rule.periodicity == 0){
                val.isEnabled = true;
            }
            return val;
        });
        this.addRule(rule);
        this.resetPeriod();
    }

    /**
     * Add a rule based on range and period
     */
    public addPeriodRange(): void {
        if (this.isAllSelected()){
            this.selectNone();
        }
        let rule = new PeriodRangeRule();
        rule.periodicity = this.tmp_add_period;
        rule.range_start = this.tmp_add_range_start;
        rule.range_end = this.tmp_add_range_end;
        this.values = this.values.map( val => {
            if( val.value >= rule.range_start && val.value <= rule.range_end ) {
                if( val.value % rule.periodicity == 0){
                    val.isEnabled = true;
                }
            }
            return val;
        });
        this.addRule(rule);
        this.resetPeriod();
        this.resetRange();
    }
}
