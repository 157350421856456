
export interface RuleInterface {
  getDescription(): string;
  getRule() : string;
}

export class AllRule implements RuleInterface {

  public getDescription() : string {
    return "All";
  }

  public getRule() : string {
    return '*';
  }

}

export class PeriodRule implements RuleInterface {

  periodicity: number;

  public getDescription(): string {
    return "Every " + this.periodicity;
  }

  public getRule() : string {
    return '*/' + this.periodicity;
  }
}

export class RangeRule implements RuleInterface {

  range_start: number;
  range_end: number;

  public getDescription(): string {
    return "Between " + this.range_start + " and " + this.range_end;
  }

  public getRule() : string {
    return this.range_start + '-' + this.range_end;
  }
}

export class ValueRule implements RuleInterface {

  value: number;

  public getDescription(): string {
    return "At "+ this.value;
  }

  public getRule() : string {
    return ''+ this.value;
  }
}

export class PeriodRangeRule extends RangeRule {

  periodicity: number;

  public getDescription(): string {
    return "Every "+ this.periodicity + ", between " + this.range_start + " and " + this.range_end;
  }

  public getRule() : string {
    return this.range_start + '-' + this.range_end + '/'+ this.periodicity;
  }

}
